.profile-picture{
    display:flex;
    overflow:hidden;
    border-radius:50%;
    width:128px;
    height:128px;
    align-items:center;
    justify-content:center;
}
.profile-picture img{
    width:100%;
    margin:auto;
}