.access-background{
    background-image:url(../../images/back01.jpeg);
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
}

.register-background{
    background-image:url(../../images/back02.jpeg);
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
}